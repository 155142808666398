import { DEFAULT_SEO } from "@constants";
import { useRouter } from "next/router";
import React, { FC } from "react";
import MicroFormat from "..";
import getUrl from "@helpers/getUrl";

/**
 *
 * JSON-LD for SERP
 *
 */

// {
//   "@context": "https://schema.org",
//   "@type": "VideoObject",
//   "name": "Example Video Title",
//   "description": "A description of the example video.",
//   "thumbnailUrl": "https://www.example.com/thumbnail.jpg",
//   "uploadDate": "2023-05-20",
//   "duration": "PT2M30S",
//   "contentUrl": "https://www.example.com/video.mp4",
//   "embedUrl": "https://www.example.com/embed/video",
//   "publisher": {
//       "@type": "Organization",
//       "name": "Example Publisher",
//       "logo": {
//           "@type": "ImageObject",
//           "url": "https://www.example.com/logo.jpg",
//           "width": 600,
//           "height": 60
//       }
//   }
// }
interface Props {
  productID: string;
  name: string;
  description: string;
  image?: string | string[];
  reviewCount?: number;
  ratingValue?: string;
  offerPrice?: number;
  offerPriceCurrency?: string;
  contentUrl?: string;
  embedUrl?: string;
  thumbnailUrl?: string;
}

const ProductMicroFormat: FC<Props> = ({
  productID,
  name,
  description,
  image,
  reviewCount,
  contentUrl,
  embedUrl,
  thumbnailUrl,
  ratingValue = "5.0",
  offerPrice = 0,
  offerPriceCurrency = "USD",
}) => {
  const router = useRouter();

  const defaultImage =
    DEFAULT_SEO.openGraph &&
    DEFAULT_SEO.openGraph.images &&
    DEFAULT_SEO.openGraph.images[0].url;
  const data = {
    "@context": "http://schema.org",
    "@type": "Product",
    name,
    description,
    image: image || defaultImage,
    contentUrl,
    embedUrl,
    thumbnailUrl,
    url: getUrl({
      asPath: router.asPath.toLowerCase(),
      keepQueryParams: false,
    }),
    productID,
  };

  if (reviewCount) {
    data["aggregateRating"] = {
      "@type": "AggregateRating",
      ratingValue,
      reviewCount,
    };
  }

  data["brand"] = {
    "@type": "Brand",
    name: "GoWithGuide",
  };

  data["offers"] = {
    "@type": "Offer",
    price: offerPrice,
    priceCurrency: offerPriceCurrency,
  };

  return <MicroFormat data={data} />;
};

export default ProductMicroFormat;
