import { PageInfo } from "@graphql/types";
import { InfiniteData } from "react-query";

export interface QueryConnection<T> {
  edges: { node: T }[];
  pageInfo: PageInfo;
}

interface PaginationData<T> {
  data: T[];
  nextCursor?: string;
  nextPage?: number;
}

export const getPaginationData = <T>(data: QueryConnection<T> | undefined) => {
  if (!data) {
    return { data: [] };
  }

  const pagination: PaginationData<T> = { data: [] };

  pagination.nextCursor = data.pageInfo.hasNextPage
    ? data.pageInfo.endCursor
    : undefined;

  data.edges.map(({ node }) => {
    pagination.data.push(node);
  });

  return pagination;
};

export const getPaginationInitialData = <T>(
  data: QueryConnection<T> | undefined
) => {
  return {
    pages: [getPaginationData(data)],
    pageParams: [],
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPaginationNodes = <T extends InfiniteData<PaginationData<any>>>(
  data: T | undefined
) => {
  if (!data || !data.pages) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const results: any[] = [];
  data.pages &&
    data.pages.map((group) => {
      group.data.map((node) => {
        results.push(node);
      });
    });

  return results;
};
