export const getMetaDataDescription = (description?: string, length = 160) => {
  if (description === undefined) {
    return "";
  }

  if (description.length <= 160) {
    return description;
  }

  return description.substring(0, length - 3) + "...";
};
