import React, { FC } from "react";
import { Trans } from "@lingui/macro";

interface SpinnerProps {
  text?: string;
  className?: string;
}

export const Spinner: FC<SpinnerProps> = ({
  text = "Loading...",
  className = "",
}) => {
  return (
    <div
      className={`inline-flex items-center text-left mt-1 self-start ${className}`}
    >
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-700"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <title id="GWGLogoTitle">
          <Trans id="common.svg.title">GoWithGuide</Trans>
        </title>
        <desc id="GWGLogoDesc">
          <Trans id="common.svg.description">
            Find your perfect tour guide at GoWithGuide. Relax and cherish
            unforgettable tours with loved ones. Create personalized tours for a
            truly memorable trip!
          </Trans>
        </desc>
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      {text && <div className="mr-4 leading-none">{text}</div>}
    </div>
  );
};
