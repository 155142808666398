import React, { FC } from "react";

const MicroFormat: FC<{ data: unknown }> = ({ data }) => {
  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(data),
        }}
      />
    </>
  );
};

export default MicroFormat;
