import Link from "next/link";
import React, { FC } from "react";
import { t } from "@lingui/macro";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";

const variants = {
  default: {
    wrapper: "",
    ul: " pr-2 py-1",
    li: "text-black",
  },
  hero: {
    wrapper: "absolute top-0 left-0 mt-3 ml-4",
    ul: "bg-black rounded-full bg-opacity-20 hover:bg-opacity-40 px-4 py-1",
    li: "text-white",
  },
};

type Variants = keyof typeof variants;

interface ListProps {
  variant: Variants;
}

const List: FC<ListProps> = ({ children, variant }) => {
  const styles = variants[variant];

  return (
    <ul className={`items-center text-sm leading-7 sm:leading-5 ${styles.ul}`}>
      {children}
    </ul>
  );
};

interface ListItemProps {
  variant: Variants;
  href?: string;
}

const ListItem: FC<ListItemProps> = ({ children, href, variant }) => {
  const styles = variants[variant];
  return (
    <li className={`inline-flex items-center ${styles.li}`}>
      {href ? (
        <>
          <Link href={getAbsoluteUrl(href)}>
            <a
              title={t({
                id: "common.link.title",
                message: "GoWithGuide - Private Tours & Local Tour Guides",
              })}
              className="hover:underline "
            >
              {children}
            </a>
          </Link>
          <div className="mx-2 opacity-40">›</div>
        </>
      ) : (
        <div className="opacity-70">{children}</div>
      )}
    </li>
  );
};

export interface BreadcrumbItem {
  text: string;
  href: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  className?: string;
  variant?: Variants;
}

export const Breadcrumb: FC<BreadcrumbProps> = ({
  items,
  className = "",
  variant = "default",
}) => {
  const styles = variants[variant];

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <List variant={variant}>
        {items.map((item, index) => (
          <ListItem
            variant={variant}
            key={index}
            href={index === items.length - 1 ? undefined : item.href}
          >
            {item.text}
          </ListItem>
        ))}
      </List>
    </div>
  );
};
